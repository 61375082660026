<template>
  <el-dialog append-to-body :visible="visible" :show-close="false" :close-on-click-modal="true" :destroy-on-close="true" @close="close" width="1144px" top="60px">
    <div class="wrap" style="padding-top: 0px;" v-loading="isLoading">
      <div class="header">增值税{{ type }}发票</div>
      <div class="content">
        <div class="buyer">
          <div class="info">
            <div class="info-header">购买方</div>
            <div class="info-content">
              <div>
                <label>名 称：</label>
                <span>{{ invoice.buyerName }}</span>
              </div>
              <div>
                <label>纳税人识别号：</label>
                <span>{{ invoice.buyerTaxNo }}</span>
              </div>
              <div>
                <label>地址、电话：</label>
                <span>{{ invoice.buyerAddressTel }}</span>
              </div>
              <div>
                <label>开户行及账号:</label>
                <span>{{ invoice.buyerBankAccount }}</span>
              </div>
            </div>
          </div>
          <div class="invoice">
            <div>
              <label>发票类型：</label>
              <span>{{ fmtBillingType(invoice.billingType) }}</span>
            </div>
            <div>
              <label>发票号码：</label>
              <span>{{ invoice.invoiceNo }}</span>
            </div>
            <div>
              <label>发票代码：</label>
              <span>{{ invoice.invoiceCode }}</span>
            </div>
            <div>
              <label>开票时间：</label>
              <span>{{ invoice.invoiceTime }}</span>
            </div>
          </div>
        </div>
        <div class="interval"></div>
        <div class="merchandise">
          <table>
            <thead>
              <tr>
                <td width="39px">序号</td>
                <td width="189px">货物或应税劳务、服务名称</td>
                <td width="209px">规格型号</td>
                <td width="93px">单位</td>
                <td width="73px">数量</td>
                <td width="115px">单价</td>
                <td width="151px">金额</td>
                <td width="87px">税率</td>
                <td>税额</td>
              </tr>
            </thead>
            <tbody v-if="!detailVisible">
              <tr v-for="(item, i) in invoice.items" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.merchandiseName }}</td>
                <td>{{ item.specification }}</td>
                <td>{{ item.unit }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ fmtPrice(item.price) }}</td>
                <td>{{ fmtPrice(item.amount) }}</td>
                <td>{{ fmtTaxRate(item.taxRate) }}</td>
                <td>{{ fmtPrice(item.taxAmount) }}</td>
              </tr>
              <tr>
                <td colspan="6" class="sum-td">合计</td>
                <td>{{ fmtPrice(sumAmount) }}</td>
                <td></td>
                <td>{{ fmtPrice(sumTaxAmount) }}</td>
              </tr>
              <tr>
                <td colspan="9" class="sum-td">
                  价税合计： <span class="case">{{ handleSmallToCapitalize(invoice.sumAmount) }}(大写)</span> <span class="case">{{ fmtPrice(invoice.sumAmount) }}(小写)</span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td></td>
                <td class="detail" @click="detail">详见销货清单</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="interval"></div>
        <div class="seller">
          <div class="info">
            <div class="info-header">销售方</div>
            <div class="info-content">
              <div>
                <label>名 称：</label>
                <span>{{ invoice.sellerName }}</span>
              </div>
              <div>
                <label>纳税人识别号：</label>
                <span>{{ invoice.sellerTaxNo }}</span>
              </div>
              <div>
                <label>地址、电话：</label>
                <span>{{ invoice.sellerAddressTel }}</span>
              </div>
              <div>
                <label>开户行及账号:</label>
                <span>{{ invoice.sellerBankAccount }}</span>
              </div>
            </div>
          </div>
          <div class="info">
            <div class="info-header">备注</div>
            <div class="info-content">{{ invoice.remark }}</div>
          </div>
        </div>
        <div class="foot">
          <div>
            <label>收款人：</label>
            <span>{{ invoice.payee }}</span>
          </div>
          <div>
            <label>复核人：</label>
            <span>{{ invoice.auditor }}</span>
          </div>
          <div>
            <label>开票人：</label>
            <span>{{ invoice.payer }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="page" class="page">
      <div class="page-view">
        <el-button type="primary" :disabled="current == 1" @click="prev">上一张</el-button>
        <div class="page-size">
          <i>{{ current }}</i
          >/<i>{{ total }}</i>
        </div>
        <el-button type="primary" :disabled="current == total" @click="next">下一张</el-button>
      </div>
    </div>
    <items-dialog append-to-body ref="itemsDialog" :visible.sync="dialogVisible" :invoice="invoice" @current-change="currentChange" @close="dialogVisible = false"></items-dialog>
  </el-dialog>
</template>

<script>
import { getPreviewInvoice } from '@/service/settlement';
import { FmtEnum } from '@/util/enumUtil';
import ItemsDialog from './ItemsDialog';
import { changeMethods } from '../../../util/mixins';

export default {
  name: 'PreviewDialog',
  components: { ItemsDialog },
  mixins: [changeMethods],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    page: {
      type: Boolean,
      default: false
    },
    invoice: {
      type: Object,
      default: () => {}
    },
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    }
  },
  computed: {
    type() {
      return ['Dzzp', 'Zzzp'].includes(this.invoice.billingType) ? '专用' : '普通';
    },
    sumAmount() {
      let sum = null;
      if (this.invoice.items) {
        sum = this.invoice.items.reduce((p, c) => p + Number(c.amount ? c.amount : 0), 0);
      }
      return sum ? sum : null;
    },
    sumTaxAmount() {
      let tax = null;
      if (this.invoice.items) {
        tax = this.invoice.items.reduce((p, c) => p + Number(c.taxAmount ? c.taxAmount : 0), 0);
      }
      return tax ? tax : null;
    },
    detailVisible() {
      return this.invoice.hasSalesList != null ? this.invoice.hasSalesList : this.invoice.items?.length > 8;
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    // 上一页
    prev() {
      this.$emit('prev');
    },
    // 下一页
    next() {
      this.$emit('next');
    },
    // 点击销货单详情
    detail() {
      this.dialogVisible = true;
      this.$emit('detail', 1, 100);
    },
    // 弹窗关闭回调
    close() {
      this.$emit('update:visible', false);
      this.$emit('close');
    },
    closeLoading() {
      this.$refs.itemsDialog.closeLoading();
    },
    // 子组件翻页
    currentChange(current, size) {
      this.$emit('detail', current, size);
    },
    // 格式化发票开具类型
    fmtBillingType(val) {
      return FmtEnum(this.$store.state.enums.BillingType, val);
    },
    // 格式化税率
    fmtTaxRate(val) {
      return val ? (/%/.test(val) ? val : Number(val) * 100 + '%') : '--';
    },
    // 格式化金额
    fmtPrice(val) {
      return val ? '¥ ' + val : null;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 30px;
  display: flex;
  color: #9d5224;
  font-size: 14px;
  text-align: left;
  align-items: center;
  border-color: #9d5224;
  flex-direction: column;
  justify-content: center;
  font-family: PingFangSC-Regular, PingFang SC;
}

.interval {
  height: 16px;
  border-left: 1px solid;
  border-right: 1px solid;
}

.header {
  width: 260px;
  font-size: 32px;
  text-align: center;
  padding-bottom: 6px;
  margin-bottom: 24px;
  border-bottom: 4px double #9d5224;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;

  .buyer,
  .seller {
    height: 160px;
    display: flex;
    border: 1px solid;
    flex-direction: row;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: row;

      .info-header {
        width: 40px;
        display: flex;
        writing-mode: tb-rl;
        letter-spacing: 6px;
        align-items: center;
        border-right: 1px solid;
        justify-content: center;
      }

      .info-content {
        width: 580px;
        display: flex;
        padding-left: 15px;
        flex-direction: column;
        border-right: 1px solid;
        justify-content: space-around;
      }
    }

    .invoice {
      width: 100%;
      display: flex;
      padding-left: 15px;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  .merchandise {
    table {
      width: 100%;
      border-collapse: collapse;

      td,
      th {
        height: 36px;
        font-weight: 400;
        text-align: center;
        border: 1px solid #9d5224;
      }

      tbody tr td {
        color: #333333;

        .case {
          padding-left: 20px;
          color: #9d5224 !important;
        }
      }

      .detail {
        color: #1890ff;
      }

      .sum-td {
        color: #9d5224;
        text-align: left;
        padding-left: 15px;
      }

      .detail:hover {
        cursor: pointer;
      }
    }
  }

  .seller {
    border: 1px solid;
  }

  .foot {
    width: 60%;
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;

    label {
      min-width: 60px;
      display: inline-block;
    }
  }

  div {
    label {
      min-width: 100px;
      display: inline-block;
    }

    span {
      color: #333333 !important;
    }
  }
}

.page {
  width: 100%;
  display: flex;
  margin-top: 26px;
  position: absolute;
  flex-direction: row;
  justify-content: center;

  .page-view {
    width: 260px;
    display: flex;
    color: #ffffff;
    font-size: 20px;
    flex-direction: row;
    justify-content: space-between;
  }

  .page-size i {
    font-style: normal;
  }
}
</style>
