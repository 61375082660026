import { get, post, download } from './index.js'

/**
 * 根据结算单id获取已开发票供用商
 * @param data
 * @returns {Promise<unknown>}
 */
export const getInvoices = param => {
  return post('/settlement-billing-invoice/getInvoicesSupplier', param).then(res => res)
}

/**
 * 获取开票信息零售商
 * @param data
 * @returns {Promise<unknown>}
 */
export const getInvoicesRetailer = param => {
  return post('/settlement-billing-invoice/getInvoicesRetailer', param).then(res => res)
}

/**
 * 导出待开发票列表
 * @param data
 * @returns {Promise<*>}
 */
export const invoicesExport = data => {
  return download('/settlement-billing-invoice/invoicesExport', data).then(res => res)
}

/**
 * 下载电子发票
 * @param data
 * @returns {Promise<*>}
 */
export const downloadInvoice = data => {
  return download('/invoice/billing/downloadInvoice', data).then(res => res)
}

/**
 * 获取图形类型
 * @param data
 * @returns {Promise<*>}
 */
export const getInvoiceImgType = data => {
  return post('/settlement-billing-invoice/getInvoiceImgType', data).then(res => res)
}

export const getInvoiceDetail = data => {
  return post('/settlement-billing-invoice/getInvoiceDetail', data).then(res => res)
}

/**
 * 混合收票
 * @param data 收票入参
 * @returns {Promise<any>} 收票出参
 */
export const reqMixReceiveInvoice = data => {
  return post('/settlement/mix-receive-invoice', data).then(res => res)
}

/**
 * 开具发票
 * @param data 开票入参
 * @returns {Promise<any>} 开票出参
 */
export const reqBillingInvoice = (data) => {
  return post('/settlement/billing-invoice', data).then(res => res)
}

export const getPreviewInvoice = param => {
  return get('/settlement-billing-invoice/getPreviewInvoice', param).then(res => res)
}
/**
 * 生成条形码
 * @param data
 * @returns {Promise<any | never>}
 */
export const getBarCode = (data) => {
  return post('/settlement/barCode', data).then(res => res)
}
/**
 * 生成待开发票
 * @param data
 * @returns {Promise<unknown>}
 */
export const generateInvoice = (data) => {
  return post('/coo/settlement/trade/buyer/generate-invoice', data).then(res => res)
}
/**
 * 未匹配发票列表查询
 * @param data
 * @returns {Promise<any | never>}
 * */
export const getUnMatchedInvoiceListByPage = (data) => {
  return post('/settlement/getUnMatchedInvoiceListByPage', data).then(res => res)
}

/**
 * 未匹配发票列表查询
 * @param data
 * @returns {Promise<any | never>}
 * */
export const handleConfirmListByPage = (data) => {
  return post('/settlement/getHandleConfirmListByPage', data).then(res => res)
}
/**
 * 手动匹配发票
 * @param {*} data
 */
export const handleConfirmMatchPush = (data) => {
  return post('/settlement/handleConfirmMatchPush', data).then(res => res)
}

export const receiveSettlement = param => {
  return get('/settlement/mix-receive-settlement', param).then(res => res)
}

/**
 * 获取销货清单
 * @param data
 * @returns {Promise<unknown>}
 */
export const getBillingInvoiceItemsVO = param => {
  return post('/settlement-billing-invoice/getBillingInvoiceItemsVO', param).then(res => res)
}

// 回写单据
export const returnSheet = (id) => {
  return post(`/settlement/return/${id}`).then(res => res)
}

// 作废单据
export const revokeSheet = (id) => {
  return post(`/settlement/revoke/${id}`).then(res => res)
}

/**
 * 结算单统计
 * @returns {Promise<any>}
 */
export const statistics = (data) => {
  return post("/settlement-billing-invoice/statistics", data).then(res => res);
}

/**
 * 全电开票导出
 * @param data
 * @returns {Promise<unknown>}
 */
export const rpaExport = data => {
  return download('/coo/settlement/trade/invoicing/rpaExport', data).then(res => res)
}

/**
 * 全电结果导入
 * @param data
 * @returns {Promise<unknown>}
 */
export const rpaImport = data => {
  return post('/coo/settlement/trade/invoicing/rpaImport', data, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => res)
}


/**
 * 导入结果下载
 * @param data
 * @returns {Promise<unknown>}
 */
export const importResultExport = data => {
  return download('/coo/settlement/trade/invoicing/importResultExport/' + data).then(res => res)
}


