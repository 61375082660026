<template>
  <el-dialog
    append-to-body
    :visible="visible"
    :show-close="false"
    :close-on-click-modal="true"
    :destroy-on-close="true"
    width="1100px"
    top="60px"
    @close="close"
  >
    <div class="m-wrap">
      <div class="m-header">销售货物或者提供应税劳务清单</div>
      <div class="m-content">
        <div class="items">
          <div>
            <label>购方名称：</label>
            <span>{{ invoice.buyerName }}</span>
          </div>
          <div>
            <label>销方名称：</label>
            <span>{{ invoice.sellerName }}</span>
          </div>
        </div>
        <div class="items">
          <div>
            <label>发票号码：</label>
            <span>{{ invoice.invoiceNo }}</span>
          </div>
          <div>
            <label>发票代码：</label>
            <span>{{ invoice.invoiceCode }}</span>
          </div>
        </div>
        <div class="items">
          <div>
            <label>数电票号：</label>
            <span>{{ invoice.eInvoiceNo }}</span>
          </div>
          <div>
            <label>开票日期：</label>
            <span>{{ invoice.invoiceTime }}</span>
          </div>
        </div>
      </div>
      <div class="m-data">
        <el-table
          :data="invoice.items"
          stripe
          v-loading="loading"
          border
          max-height="400"
          :header-cell-style="handleHeaderCellStyle"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            fixed="left"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="merchandiseName"
            label="项目名称"
            fixed="left"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="specification"
            label="规格型号"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="unit"
            label="单位"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="quantity"
            label="数量"
            header-align="center"
            align="right"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="单价"
            header-align="center"
            align="right"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="amount"
            :formatter="fmtAmount"
            label="金额"
            header-align="center"
            align="right"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="taxRate"
            :formatter="fmtTaxRate"
            label="税率"
            header-align="center"
            align="center"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="taxAmount"
            :formatter="fmtAmount"
            label="税额"
            header-align="center"
            align="right"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="sumAmount"
            :formatter="fmtAmount"
            label="合计"
            header-align="center"
            align="right"
            min-width="120"
          ></el-table-column>
          <el-table-column
            label="税分编码"
            prop="taxClassCode"
            min-width="200"
            show-overflow-tooltip
          />
          <el-table-column
            label="税分名称"
            prop="taxClassName"
            min-width="160"
            show-overflow-tooltip
          />
        </el-table>
      </div>
      <div class="m-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="current"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="invoice.items.length"
        >
        </el-pagination>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { tableStyle, changeMethods } from "@/util/mixins";

export default {
  name: "ItemsDialog",
  mixins: [tableStyle, changeMethods],
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      default: () => {
        return {
          items: [],
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      current: 1,
      pageSize: 100,
    };
  },
  methods: {
    // 翻页
    handleCurrentChange(val) {
      this.loading = true;
      this.$emit("current-change", val, this.pageSize);
    },
    // 关闭Loading
    closeLoading() {
      this.loading = false;
    },
    // 弹窗关闭
    close() {
      this.$emit("close");
      this.$emit("update:visible", false);
    },
    fmtAmount(row, column) {
      let value = row[column.property];
      return this.formatMoney(value);
    },
    fmtTaxRate(row) {
      return row.taxRate
        ? /%/.test(row.taxRate)
          ? val
          : Number(row.taxRate) * 100 + "%"
        : "--";
    },
  },
};
</script>

<style lang="scss" scoped>
.m-wrap {
  display: flex;
  padding: 0 24px 24px;
  flex-direction: column;
}

.m-header {
  width: 100%;
  color: #333333;
  font-size: 32px;
  text-align: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e9e9e9;
}

.m-content {
  display: flex;
  padding: 24px 0 8px;
  flex-direction: row;
  justify-content: space-between;

  .items {
    width: 50%;
    color: #666666;
    font-size: 14px;
    text-align: left;

    div {
      margin-bottom: 16px;
    }

    span {
      color: #333333;
    }
  }
}

.m-page {
  margin-top: 24px;
}
</style>
